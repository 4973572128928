{
  "welcome": "Selamat datang",
  "bannerCTA": "มียอดจองไปแล้วกว่า {total} ที่นั่ง จองโต๊ะของคุณวันนี้!",
  "firstReview": "ใหม่ {review} รีวิวแรก รับไปเลย Voucher {point} บาท!",
  "lastReservationCTA": "ร้านฮิต! จองครั้งสุดท้ายเมื่อ {time}",
  "newRestaurantCTA": "ใหม่! จองและรีวิวเพื่อรับเวาเชอร์ {point} บาท!",
  "totalRestaurantBookingCTA": "มาแรง! {total} คนจองร้านนี้แล้ว",
  "viewOtherBranch": "ดูสาขาอื่น",
  "acceptVoucher": "รับเวาเชอร์",
  "netPerson": "ราคาสุทธิ/ท่าน",
  "netSet": "ราคาสุทธิ/เซ็ต",
  "subscribeNewsLetter": "ฉันต้องการรับส่วนลดมูลค่า {amount}, ข้อเสนอรวมถึงโปรโมชั่นต่าง ๆ และการอัปเดตฟีเจอร์ ใหม่จากทาง Hungry Hub",
  "alreadyMember": "สมาชิกของเราเข้าระบบที่นี่",
  "registerTermCondition": "การลงชื่อเข้าใช้หรือสร้างบัญชีสมาชิก หมายถึงคุณยอมรับ <a class='text-blue-500 hover:underline' href='{tncLink}' target='_blank'>ข้อกำหนดและเงื่อนไขในการใช้งาน</a> และ <a class='text-blue-500 hover:underline' href='{privacyPolicyLink}' target='_blank'>นโยบายความเป็นส่วนตัว</a>",
  "dineIn": "ทานที่ร้าน",
  "delivery": "เดลิเวอรี่",
  "experience": "ประสบการณ์",
  "voucher": "ส่วนลด",
  "freeDelivery": "ฟรีค่าจัดส่ง",
  "forBusiness": "สำหรับธุรกิจ",
  "giftCard": "กิ๊ฟการ์ด",
  "blog": "บล็อก",
  "downloadApp": "ดาวน์โหลดแอปฯ",
  "help": "ช่วยเหลือ",
  "allowCookie": "โปรดอนุญาตให้ใช้คุกกี้เพื่อพัฒนาและปรับปรุง ให้ระบบทำงานได้ถูกต้องและดียิ่งขึ้น",
  "noInternet": "โปรดเช็คการเชื่อมต่ออินเทอร์เน็ตของคุณ",
  "language": "ภาษา",
  "signIn": "เข้าสู่ระบบ",
  "signInRegister": "เข้าสู่ระบบ / ลงทะเบียน",
  "next": "ถัดไป",
  "totalAmount": "สรุปยอดเงินทั้งหมด",
  "pleaseEnterPhone": "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
  "pleaseEnterEmail": "กรุณากรอกอีเมลของคุณ",
  "shopeePay": "SHOPEE PAY",
  "debitCredit": "บัตรเดบิต / บัตรเครดิต",
  "qrPayment": "จ่ายด้วย QR Code",
  "shopeePayPaymentText": "ระบบจะทำการเชื่อมต่อไปที่ Shopee Pay โดยอัตโนมัติ",
  "trueWalletPaymentText": "ระบบจะทำการเชื่อมต่อไปที่ True Wallet โดยอัตโนมัติ",
  "weWillGenerateAmountPayment": "ระบบจะสร้าง QR Code สำหรับชำระเงิน {amount} บาท",
  "useYourBank": "หลังจากกดปุ่ม 'ยืนยันและชำระเงิน' โปรดใช้แอปพลิเคชั่นธนาคารของคุณแสกน QR Code เพื่อชำระเงิน",
  "pleaseWait": "โปรดรอ",
  "applyFilter": "ใช้ตัวกรอง",
  "priceRange": "ช่วงราคา",
  "startingPrice": "ราคาเริ่มต้น",
  "highestPrice": "ราคาสูงสุด",
  "showResult": "แสดง ({total} ผลลัพธ์)",
  "showResults": "แสดง ({total} ผลลัพธ์)",
  "whatsYourBudget": "คุณตั้งงบไว้เท่าไหร่?",
  "underPrice": "ต่ำกว่า {amount} บาท",
  "pricePerPerson": "ราคาต่อคน",
  "pricePerPackage": "ราคาต่อแพ็กเกจ",
  "popularPlace": "สถานที่ยอดนิยม",
  "allLocation": "สถานที่ทั้งหมด",
  "shoppingMall": "ห้างสรรพสินค้า",
  "popularZone": "ย่านดัง",
  "btsRoute": "เส้นทาง BTS",
  "mrtRoute": "เส้นทาง MRT",
  "seeMore": "ดูทั้งหมด",
  "seeLess": "แสดงน้อยลง",
  "name": "ชื่อ",
  "egHomeOffice": "บ้าน, ออฟฟิศ",
  "address": "ที่อยู่",
  "addressDetails": "รายละเอียดที่อยู่",
  "egFloorUnit": "ชั้นที่เท่าไร, เลขที่เท่าไร, อาคารไหน",
  "noteToDriver": "รายละเอียดถึงคนขับ",
  "contactName": "ชื่อ",
  "contactNumber": "เบอร์โทร",
  "setPrimary": "ตั้งเป็นที่อยู่หลัก",
  "save": "บันทึก",
  "cancel": "ยกเลิก",
  "delete": "ลบ",
  "myAddress": "ที่อยู่",
  "addAddress": "เพิ่มที่อยู่",
  "editAddress": "แก้ไขที่อยู่",
  "addNewAddress": "เพิ่มที่อยู่ใหม่",
  "confirmNewAddress": "ยืนยันที่อยู่ใหม่",
  "edit": "แก้ไข",
  "paymentMethod": "วิธีการชำระเงิน",
  "addCreditDebitCard": "เพิ่มบัตรใหม่",
  "creditCardType": "ประเภทบัตรเครดิต",
  "cardNumber": "หมายเลขบัตร 16 หลัก",
  "cardHolderName": "ชื่อผู้ถือบัตร",
  "expiresOn": "วันหมดอายุ",
  "securityCodeCVV": "รหัสรักษาความปลอดภัย",
  "pickLocation": "ระบุที่อยู่",
  "primary": "บัตรใบหลัก",
  "currentLocation": "ตำแหน่งปัจจุบัน",
  "savedAddress": "ที่อยู่ที่บันทึกไว้",
  "recentlyUsed": "ที่อยู่ล่าสุด",
  "new": "ใหม่",
  "trending": "ร้านฮิต",
  "popular": "มาแรง",
  "search": "ค้นหา",
  "findARestaurant": "ค้นหาร้านอาหาร",
  "packageTypeSectionTitle": "ดีลร้านอาหารดีๆ แบบไม่เคยมีที่ไหนมาก่อน",
  "packageTypeSectionSubTitle": "เรียนรู้เพิ่มเติมเกี่ยวกับแพ็คเก็จที่ดีที่สุดของเรา",
  "restaurant": "ร้านอาหาร",
  "register": "สมัครสมาชิก",
  "signOut": "ออกจากระบบ",
  "earnPoints": "รับคะแนน",
  "first5Reviews": "5 รีวิวแรก",
  "bonus": "โบนัส",
  "from": "จาก",
  "deliveryHelperText": "ฟรีค่าส่ง {distance} กม.แรก และกิโลเมตรต่อไป {exceedDeliveryFee} บาท/กม.",
  "deliveryPricingTierText": "ส่งฟรี {distance} แรก และกิโลเมตรต่อไป {pricePerKM} บาท/กม.",
  "noMinimumCharge": "ไม่มีค่าใช้จ่ายขั้นต่ำ",
  "spend": "ค่าใช้จ่าย {value}",
  "freeDeliveryPromotion": "โปรโมชั่นส่งฟรี",
  "filterByPDT": "กรุณากรอก จำนวนคน วันที่ และ เวลา",
  "howManyPeople": "ระบุจำนวนคน",
  "bigGroup": "กลุ่มใหญ่",
  "adult": "ผู้ใหญ่",
  "kid": "เด็ก",
  "selectDate": "เลือกวันที่",
  "upTo90DaysInAdvance": "จองล่วงหน้าได้ถึง 90 วัน",
  "today": "วันนี้",
  "daysOfWeek": ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
  "selectTime": "เลือกเวลา",
  "atLeast15MinsInAdvance": "จองล่วงหน้าอย่างน้อย 15 นาที",
  "total": "รวมทั้งหมด",
  "totalDeposit": "จำนวนเงินที่มัดจำ",
  "totalPrepayment": "ชำระเงินล่วงหน้าเต็มจำนวน",
  "totalOrderAmount": "ยอดรวมที่ต้องชำระ",
  "totalPayAtRestaurant": "ยอดรวมที่ต้องจ่ายที่ร้านอาหาร",
  "personalInformation": "ข้อมูลส่วนตัว",
  "joinOrSignIn": "สมัคร / ลงชื่อเข้าใช้",
  "forFasterCheckout": "เพื่อความรวดเร็วในการชำระครั้งถัดไป!",
  "toUseYourGiftCard": "เพื่อใช้ Gift Card ของคุณ",
  "offers": "สิทธิพิเศษ",
  "dontForgetToUseOurSpecialOffers": "อย่าลืมใช้ส่วนลดพิเศษของเรา!",
  "loginToSaveMore": "ลงชื่อเข้าใช้ เพื่อสิทธิพิเศษมากขึ้น",
  "enterPromoCodeHere": "ค้นหาโค้ดส่วนลด",
  "totalPoints": "{totalPoints} คะแนน",
  "availableForDiscount": "ใช้เป็นส่วนลด",
  "availableForRedeem": "ใช้เป็นส่วนลด",
  "hungryhubPointRedemption": "Hungry Points Redemption",
  "redeemPoints": "แลกคะแนน",
  "dontUsePoint": "ไม่ใช้คะแนน",
  "selectYourGiftCard": "เลือก Gift Card ของคุณ",
  "summary": "สรุปยอดชำระ",
  "pointsRedeemed": "{point} คะแนน ที่แลก",
  "termAndConditions": "ข้อกำหนดและเงื่อนไข",
  "byClickingConfirmationBooking": "การคลิก <q>ยืนยันการจอง<q> แสดงว่าคุณยอมรับ",
  "submit": "เสร็จ",
  "reviewedPackages": "แพ็กเกจที่รีวิว",
  "information": "รายละเอียด",
  "youMightAlsoLike": "ร้านอื่นที่น่าสนใจ",
  "howToUseVoucher": "วิธีการใช้เวาร์เชอร์",
  "buyAVoucher": "ซื้อเวาร์เชอร์",
  "reviews": "รีวิว",
  "recommendedStaycations": "สเตเคชั่นที่แนะนำ",
  "staycation": "สเตเคชั่น",
  "acceptOffersAndGiftCard": "รับกิ๊ฟการ์ด",
  "comeMorePayLess": "ยิ่งมาเยอะ ยิ่งจ่ายน้อย",
  "acceptGiftCard": "รับกิ๊ฟการ์ด",
  "titleBuyVoucher": "ซื้อเวาเชอร์",
  "mostLovedByUsers": "ร้านที่ใคร ๆ ก็ชอบ",
  "addressName": "ชื่อที่อยู่",
  "location": "ที่ตั้ง",
  "phone": "เบอร์โทรศัพท์",
  "removeAddress": "ลบที่อยู่",
  "setAsPrimary": "ตั้งเป็นบัตรเริ่มต้น",
  "view": "ดูเพิ่มเติม",
  "saved": "ประหยัด",
  "copy": "คัดลอก",
  "share": "แชร์",
  "settings": "การตั้งค่า",
  "version": "เวอร์ชั่น",
  "privacyPolicy": "นโยบายความเป็นส่วนตัว",
  "logOut": "ออกจากระบบ",
  "discardChange": "ยกเลิกการเปลี่ยนแปลง",
  "saveChange": "บันทึกการเปลี่ยนแปลง",
  "myPreferences": "แนะนำร้านเหล่านี้",
  "creditDebit": "เครดิต/เดบิตการ์ด",
  "youDontHaveCC": "คุณไม่มีบัตรเครดิต",
  "hungryPoint": "ฮังกรี้พ้อยท์",
  "giftCardValue": "มูลค่ากิฟต์การ์ด",
  "inTotal": "จำนวน",
  "myVoucher": "เวาเชอร์ของฉัน",
  "booked": "การจอง",
  "outlets": "ร้าน",
  "bookingId": "รหัสการจอง",
  "rateYourExperience": "ให้คะแนนประสบการณ์ของคุณ",
  "bookAgain": "จองอีกครั้ง",
  "giftCards": "กิฟต์การ์ด",
  "payment": "ยอดชำระ",
  "reloadQRCode": "เปลี่ยน QR Code",
  "tags": "แท็ก",
  "bloggerPost": "รีวิวจากบล็อกเกอร์"
}
